.left{
    width: 48%;
}
.left-img{
    width: 80%;
}
.right{
    width: 48%;
}

.form-control{
    background-color: rgba(0, 0, 0, 0.2);
}


@media (max-width: 650px) {
    .contact{
        flex-direction: column;
    }
    .left, .left-img, .right{
        width: 100%;
    }
}