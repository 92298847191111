.verticl-line {
    top: 3rem;
    height: 262vh;
    width: 2px;
    background-color: white;
}

.horizontal-left {
    width: 20rem;
    height: 2px;
    background-color: #3A606E;
    top: 8rem;
    right: 157px;



}

.horizontal-right {
    width: 20rem;
    height: 2px;
    background-color: rgb(3, 138, 3);
    top: 10rem;
    right: -157px;



}

.vista-right {
    background-color: rgb(58, 193, 247);
}

.bg-memories {

    color: #666666;
}

.bg-prime {
    color: gray;
}

.bg-evfix {
    color: rgb(3, 138, 3);
}

.bg-vista {
    color: rgb(58, 193, 247);
}

.vista-para {
    color: "#333333";
}

.vista-list {
    color: "#333333";
}

.memories-para {
    color: "#333333";
}

.memories-list {
    color: "#333333"
}

.evfix-para {
    color: rgb(85, 65, 43);
}

.evfix-list {
    color: rgb(85, 65, 43);
}

.memories-world {
    width: 90%;
    gap: 6rem;
    margin: auto;
    margin-top: 2rem;
}

.prime-bazaar {
    width: 90%;
    gap: 6rem;
    margin: auto;
    margin-top: 4rem;

}

.evfix {
    width: 90%;
    gap: 6rem;
    margin: auto;
    margin-top: 4rem;

}

.laptop {
    width: 50%;
    z-index: 2;
}

.prime-laptop {
    width: 50%;

    z-index: 2;
}

.laptop-img {
    width: 70%;
    transition: 0.1s linear;
}

.laptop-img:hover,
.evfix-laptop-img:hover {
    scale: 1.1;

}

.prime-laptop-img {
    width: 70%;
    transition: 0.1s linear;


}

.evfix-laptop {
    width: 50%;
    z-index: 2;
}

.evfix-img {
    width: 70%;

}

.evfix-laptop-img {
    width: 70%;
    transition: 0.1s linear;

}

.circle {

    position: absolute;
    width: 17px;
    height: 17px;
    background-color: white;
    border-radius: 106%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid#3A606E;
    top: 10.5rem;
    z-index: 10;
}

.circle-prime {

    position: absolute;
    width: 17px;
    height: 17px;
    background-color: white;
    border-radius: 106%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #2E3B4E;
    bottom: 47.3rem;
}

.circle-evfix {

    position: absolute;
    width: 17px;
    height: 17px;
    background-color: white;
    border-radius: 106%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid rgb(3, 138, 3);
    top: 31rem;
    z-index: 10;
}

.circle-vista {
    position: absolute;
    width: 17px;
    height: 17px;
    background-color: white;
    border-radius: 106%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid rgb(58, 193, 247);
    bottom: 16.7rem;
    z-index: 10;
}

.about-memories {
    width: 50%;

}

.about-prime {
    width: 50%;

}

.about-evfix {
    width: 50%;

}

@media (max-width: 1024px) {
    .verticl-line {
        height: 190vh;
    }
    .circle-evfix{
        top: 36rem;
    }
    .circle-prime{
        bottom: 60rem;
    }
    .circle-vista{
        bottom: 21.2rem;
    }
}


@media (max-width: 650px) {
    .verticl-line {
        display: none;
    }

    .horizontal-left {
        display: none;
    }

    .horizontal-right {
        display: none;
    }

    .circle {
        display: none;
    }

    .circle-prime {
        display: none;
    }

    .circle-evfix {
        display: none;
    }
    .circle-vista {
        display: none;
    }

    .memories-world,
    .prime-bazaar {
        flex-wrap: wrap;
        justify-content: center;
    }

    .evfix {
        flex-wrap: wrap-reverse;
        justify-content: center;
    }

    .laptop,
    .prime-laptop,
    .evfix-laptop {
        width: 100%;
    }

    .laptop-img,
    .prime-laptop-img,
    .evfix-laptop-img {
        width: 100%;
    }

    .about-memories,
    .about-prime,
    .about-evfix {
        width: 100%;
        text-align: center;
    }

    .bg-memories {
        /* color: rgba(0, 128, 128, 1); */
        color: rgba(139, 0, 0, 1);

    }

    .bg-evfix {
        color: rgb(1, 59, 1);
    }

    .bg-prime {
        color: black;
    }

    .memories-para,
    .memories-list,
    .evfix-list,
    .evfix-para,
    .prime-para,
    .prime-list,
    .vista-para,
    .vista-list {
        color: white;
    }

}