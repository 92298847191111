.bg-header{
    background-color: #909299;
    padding-inline: 2rem;
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 2000;
}

/* Styles for the sidebar menu */
.sidebar-menu {
    position: absolute;
    top: 3rem;
    left: -250px; /* Hide off-screen initially */
    width: 10rem; /* Sidebar width */
    height: 8rem;
    background-color: #909299; /* Dark background color */
    color: white;
    padding: 1rem;
    transition: left 0.3s ease-in-out; /* Smooth slide-in effect */
    display: flex;
    flex-direction: column;
    gap: 1rem;
    z-index: 1000;
}

/* Show sidebar when toggled */
.sidebar-menu.show {
    left: 0;
}

/* Inline menu for larger screens */
@media (min-width: 768px) {
    .sidebar-menu {
        position: static;
        display: flex;
        height: auto;
        width: auto;
        background-color: transparent;
        color: inherit;
        flex-direction: row;
        align-items: center;
        gap: 1.5rem;
    }
}

/* Button styling */
.btn {
    font-size: 1.5rem;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}

