.bg-image {
    background-image: url('../../../public/images/back.avif');
    height: 100vh;
    width: 100vw; /* Ensure it covers the full viewport width */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: fixed; /* Fixed positioning to keep it in place */
    top: 0;
    left: 0;
    z-index: -1; /* Places it behind the scrolling content */
}
.bg-color{
    height: 100vh;
    background-color: black;
    opacity: 0.3;
}

