.about{
    position: relative;
    top: 34rem;
    height: 270vh;
   
   
    /* background-color: #f5f6fa; */
    background-color: #c8cad3;
    /* background-color: #efe9e9; */
    /* background-color:white; */
}
.content-wrapper-about {
    text-align: center;
    position: absolute;
    margin-top:-435px;
    z-index: -1; /* Brings content above the background */
    padding-top: 3.5rem; /* Adjust top padding as needed */
    display: flex;
    flex-direction: column;
    width: 100%;
    /* align-items: center;
    justify-content: center; */
    min-height: 100vh; /* Ensures content fills at least one viewport height */
    color: white;
}

@media (max-width: 1024px) {
    .about{
        height: 200vh;
    }
}
@media (max-width: 650px){
    .about{
        height: 0vh;
    }
}